@font-face{
    font-family: "Colaborate Regular";
    src: url("../files/fonts/colaborate/colaborate_regular_macroman/ColabReg-webfont.woff");
}
@font-face{
    font-family: "OpenSans Bold";
    src: url("../files/fonts/OpenSans/OpenSans-Bold.ttf");
}
@font-face{
    font-family: "OpenSans Regular";
    src: url("../files/fonts/OpenSans/OpenSans-Regular.ttf");
}

@font-face{
    font-family: "Poppins Regular";
    src: url('../files/fonts/Poppins/Poppins-Regular.ttf');
}

@font-face{
    font-family: "Poppins Bold";
    src: url('../files/fonts/Poppins/Poppins-Bold.ttf');
}

$font-size-basic: 16px;

$font-family: 'Poppins Regular', sans-serif;

$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
