.image-text{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    p{
        text-align: left;
        a{
            color: $color-grey;
            transition: 450ms ease-in-out;

            &:hover{
                color: $color-turquoise;
            }
        }
    }

    

    .image-text__image-wrapper, .image-text__text{
        width: 100%;
    
        @media screen and (min-width: $breakpoint) {
            width: 49%;
        }
    }
    
    .image-text__image-wrapper{
        position: relative;
        padding-top: 75%;
        margin-bottom: 4rem;

        
        @media screen and (min-width:$breakpoint){
            padding-top: 37.5%;
            margin-bottom: 0;
        }

        p{
            font-size: 0.5rem;
            margin: 0;

            a{
                color: $color-grey;
                transition: 450ms ease-in-out;

                &:hover{
                    color: $color-turquoise;
                }
            }
        }

        .image-text__image{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    .image-text__image-wrapper--alt{

        padding-top: 48%;

        @media screen and (min-width:$breakpoint){
            padding-top: 24%;
            margin-bottom: 0;
        }
    }

    .image-text__image-wrapper--alt-two{
        padding-top: 69%;

        @media screen and (min-width:$breakpoint){
            padding-top: 34%;
            margin-bottom: 0;
        }
    }

    .image-text__text{
        

        
        p{
            margin-top: 0;

            a{
                color: $color-grey;
                text-align: center;
                transition: 450ms ease-in-out;

                &:hover{
                    color: $color-turquoise;
                }
            }
        }
    
    }

    .image-text__text--alt{
        text-align: center;
        @media screen and (min-width:$breakpoint){
            width: 100%;
        }

        p{
            margin: 0;
        }
    }

    .image-text__boxes{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin: 2rem 0 0 0;


        .image-text__boxes__single-box{ 
            height: 15vh;
            width: 100%;
            box-sizing: border-box;
            border: 2px solid $color-grey-transparent;
            margin: 0 0 2rem 0;
            border-radius: .5rem;



            @media screen and (min-width:$breakpoint){
                border: 1px solid $color-grey-transparent;
            }

            

            &.last-box-row-mobile{
                margin: 0 0 0 0;
            }

            @media screen and (min-width: $breakpoint) {
                width: 32%;
                &.last-box-row-desktop{
                    margin: 0 0 0 0;
                }
            }

            .image-text__boxes__single-box__top{
                height: 50%;
                padding: 1vh;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .image-text__boxes__single-box__top__icon{
                    width: 10%;
                    margin-left: 1rem;
                    margin-right: 1rem;
                    svg{
                        width: auto;
                        max-height: 5vh;
                    }
                }
            }

            .image-text__boxes__single-box__top--alt{

                p{
                    width: 100%;
                    text-align: center;
                }
                
            }

            .image-text__boxes__single-box__bottom{
                border-top: 0.1px solid $color-grey-transparent;
                height: 100%;
                padding: 1vh;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                
                p{
                    text-align: center;
                }

                .image-text__boxes__single-box__bottom__image-wrapper{
                    width: 80%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img{
                        max-width: 60%;
                    }
                }

                
            }
        }
    }

    .image-text__boxes--alt{
        .image-text__boxes__single-box{
            height: 25vh!important;
        }
    }
}    