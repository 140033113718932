@import "../modules/hamburgers-settings.scss";
@import "../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";


.header{
    z-index: 6;
    display: flex;
    justify-content: space-between;
    width: 100%;
    // height: 10rem;
    padding: 3vw 0 3vw 0;

    background-color: $color-white;
    -webkit-box-shadow: 0px 6px 8px -1px $color-grey;
    -moz-box-shadow: 0px 6px 8px -1px $color-grey;
    box-shadow: 0px 6px 8px -1px $color-grey;
    

    .header__content{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header__content__logo{

            a{
                display: flex;
                align-items: center;
                width: 100%;

                svg{
                    width: 100%;
                }
            }
        }

        .header__content__nav{

            display: none;

            @media screen and (min-width:$breakpoint) {
                display: unset;
            }

            ul{
                display: flex;
                list-style: none;
                margin: 0;
                
                
                li{
                    font-size: 4vh;
                    font-family: 'Poppins Regular';
                    padding: 0 2rem 0 2rem;
                    
                    a{
                        text-decoration: none;
                        color: $color-black;
                        position: relative;
                        display: inline-block;

                        &::after{
                            display: block;
                            content: '';
                            position: absolute;
                            border-bottom: 2px solid $color-black;
                            transform: scaleX(0);
                            transition: transform 450ms ease-in-out;
                            bottom: -0.2rem;
                            left: 0;
                            right: 0;
                            width: 100%;
                        }
                    }

                    a:hover{
                        &::after{
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }
    
        .hamburger-wrapper{
            width: 50%;
            display: flex;
            justify-content: flex-end;

            @media screen and (min-width:$breakpoint){
                display: none;
            }

            #hamburger{
                
                &:focus {
                    outline: none;
                }

                
            }
        }
    }
}

.scrolled .header{
    position: fixed;
    top: 0;
    animation-name: HeaderSlideIn;
    animation-duration: .5s;
    padding: 1vw 0 1vw 0;
}

#mobile-nav-wrapper{
    z-index: 5;
    width: 100%;
    position: fixed;
    transform: translateX(100%);
    transition: 450ms ease-in-out;
    // height: calc(100% - 10rem);
    background-color:$color-white;
    overflow-y: auto;
    font-family: 'Poppins Regular';

    @media screen and (min-width:$breakpoint){
        display: none;
    }
    


    &.active{
        transform: translateX(0);
    }

    .mobile-nav{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;

        .mobile-nav__section{
            list-style: none;
            width: 100%;
            padding-left: 0;
    
    
            li{
                a{
                    text-decoration: none;
                }
            }
        }
    
        .mobile-nav__section-navpoints{

            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            margin: 5rem 0 5rem 0;
            font-family: 'Poppins Regular';
    
            li{
                width: 90%;
                text-align: center;
                font-size: 3vh;
                font-weight: 700;
                padding: 5% 0 5% 0;
                border-top: .1px solid $color-grey;

                &:first-child{
                    border-top: none;
                }
    
                a {
                    text-decoration: none;
                    color: $color-turquoise;
                }
            }
        }
    
        .mobile-nav__section-essentials{

            margin-bottom: 10rem;

            li{
                width: 100%;
                font-size: 1.5vh;
                text-align: center;
    
                &:last-child{
                    margin: 5rem 0 0 0;
                }
                
    
                a{
                    color: $color-black;
                }
            }
        }
    
        .mobile-nav__section-footer{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            -webkit-box-shadow: 0px -6px 8px -1px $color-grey;
            -moz-box-shadow: 0px -6px 8px -1px $color-grey;
            box-shadow: 0px -6px 8px -1px $color-grey;
            height: 10rem;

            .mobile-nav__section-footer__icon-wrapper{
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                width: 90%;
                padding: 0;
                margin: 0;

                li{
                    list-style: none;
                
                    a{
                        display: flex;
                        justify-content: space-between;
        
                        svg{
                            height: 8vw;
                        }
                    }
                }

            }
    
        }
    }

}

.fixed-contact{

    display: none;

    @media screen and (min-width:$breakpoint){
        display: unset;
    }

    position: fixed;
    right: 0;
    top: 50%;
    background-color: $color-turquoise;
    border-radius: .5rem 0 0 .5rem;
    width: 4%;

    ul{
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li{
            

            a{
                transition: 450ms ease-in-out;
                display: flex;
                justify-content: center;
                padding: .5rem 0 .5rem 0;
                height: 2rem;

                &:first-child{
                    padding-top: 1rem;
                }
                &:last-child{
                    padding-bottom: 1rem;
                }

                &:hover{
                    opacity: 0.7;
                }
                

                svg{
                    width: 50%;
                }
            }
        }
    }
}

.anchor{
    position: absolute;
    top: -12rem;
    left: 0;

}

@keyframes HeaderSlideIn {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

