.legals{

    margin: 5rem 0 5rem 0;

    a{
        color:$color-grey;
        transition: 450ms ease-in-out;

        &:hover{
            color: $color-turquoise;
        }
    }
    
    h2, h3{
        text-align: left;
    }
}