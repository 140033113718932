html, body{
    height: 100%;
    font-family: $font-family;
    scroll-behavior: smooth;
    
    &.fix{
      position: fixed;
      width: 100%;
      overflow: hidden;
    }
  }
  
  body{
    margin: 0;
    background-color:$color-white;
    color: $color-black;

    .inside{
      width: 90%;
      max-width: 1363px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
      height: 100%;
    }
  }
  
  img{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  .module{
    margin: 10rem 0 10rem 0;
  }

  .module--last{
    margin: 10rem 0 0 0;
  }

  .intro{
    margin-top: 5rem!important;
    margin-bottom: 2rem!important;
  }
