// define base colors
$color-white: rgb(255, 255, 255);
$color-black: rgb(6, 13, 15);
$color-grey: rgb(144, 152, 157);
$color-mid-grey: rgb(199, 204, 207);
$color-light-grey: rgb(230, 234, 236);
$color-orange: rgb(255, 173, 81);
$color-turquoise: rgb(70, 169, 184);
$color-blue: rgb(56,128,177);

$color-white-transparent: rgba(255, 255, 255, 0.7);
$color-black-transparent: rgba(6, 13, 15, 0.7);
$color-grey-transparent: rgba(144, 152, 157, 0.7);
$color-turquoise-transparent: rgba(70, 169, 184, 0.7);