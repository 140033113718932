// JUST FOR INTRO!!

.text{
    margin-top: 0!important;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    

    .text__with-image{
        margin-bottom: 4rem;
        align-self: flex-start;
    }

    .text__left, .text__right{
        width: 100%;

        :first-child{
            margin-top: 0;
        }

        @media screen and (min-width:$breakpoint){
            width: 47%;

            :last-child{
                margin-bottom: 0;
            }
        }

        a{
            color: $color-grey;
            transition: 450ms ease-in-out;

            &:hover{
                color: $color-turquoise;
            }
        }
    }

    .text__right{
        :last-child{
            margin-bottom: 0;
        }
    }
}
