// html{
//   font-size: 62.5%;
// }  
  
  body {
    font-family: $font-family;
    font-size: 2.5vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $color-black;

    ul, ol {
      padding-left: 1em;
    }
  
    h1, h2, h3, h4{
      font-family: 'Poppins Regular';
      text-align: center;
      width: 100%;
      font-weight: 700;

      @media screen and (min-width:$breakpoint){
        margin: 0 0 1.5rem 0;
      }
    }
  
    h2{
      font-size: 4vh;
      margin: 0 0 3rem 0;
    }

    h3{
      font-size: 3vh;
      margin: 4rem 0 3rem 0;
    }
  }
  
  
  @mixin strong {
    font-family: $font-family;
  }


  
