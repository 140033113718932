// Swiper One 

.swiper{

    a{
        color: $color-grey;
        transition:450ms ease-in-out;

        &:hover{
            color: $color-turquoise;
        }
    }

    h2{
        text-align: center;
    }

    .swiper-container, .swiper-container--alt, .swiper-container--alt-two{
        min-height: 50vh;
        max-height: 100%;
        border-radius: .5rem;
        color: $color-white;

        .swiper-slide{
            height: 50vh;
            
            

            @media screen and (min-width: $breakpoint){
                
                .swiper-slide__description{
                    opacity: 0;
                    transition: 450ms ease-in-out;
                    
                }

                &:hover{
                    .swiper-slide__description{
                        opacity: 1;
                    }
                    .swiper-slide__image{
                        opacity: 0.7;
                    }
                }
                
            }


            .swiper-slide__image{
                background-position: center;
                background-size: cover;
                height: 100%;
                width: 100%;
                transition: 450ms ease-in-out;

                @media screen and (min-width:$breakpoint) {
                    background-position: unset;
                }
    
                &.sector_one{
                    background-image: url('../files/images/sectors/sector_one.jpg');
                }
                &.sector_two{
                    background-image: url('../files/images/sectors/sector_two.jpg');
                }
                &.sector_three{
                    background-image: url('../files/images/sectors/sector_three.jpg');
                }
                &.sector_four{
                    background-image: url('../files/images/sectors/sector_four.jpg');
                }
                &.sector_five{
                    background-image: url('../files/images/sectors/sector_five.jpg');
                }
                &.sector_six{
                    background-image: url('../files/images/sectors/sector_six.jpg');
                }
                &.sector_seven{
                    background-image: url('../files/images/sectors/sector_seven.jpg');
                }
                &.christian_placeholder{
                    background-image: url('../files/images/team/avatar_man.png');
                }
                &.lothar_placeholder{
                    background-image: url('../files/images/team/lothar_gesslein.jpeg');
                }
                &.jonathan_placeholder{
                    background-image: url('../files/images/team/jonathan_schweppe.jpg');
                }
                &.fynn_placeholder{
                    background-image: url('../files/images/team/avatar_man.png');
                }
                &.malte_placeholder{
                    background-image: url('../files/images/team/malte_muench.png');
                }
                &.daniel_placeholder{
                    background-image: url('../files/images/team/avatar_man.png');
                }
                &.tobias_placeholder{
                    background-image: url('../files/images/team/avatar_man.png');
                }

            }
    
            .swiper-slide__description{
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $color-turquoise-transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

    
                p{
                    width: 90%;
                }
            }


        }

        
    }
}

.swiper-button-prev, .swiper-button-next {
    color: $color-turquoise!important;
    top: 40%!important;

    &::after{
        font-size: 8vh!important;
    }

    @media screen and (min-width:$breakpoint) {
        top: 50%!important;
    }
}

.swiper-button-prev{
    left: 5vw!important;
}

.swiper-button-next{
    right: 5vw!important;
}

.swiper-button-prev--alt, .swiper-button-next--alt{
    top: 21vh!important;
}

// Swiper Two   

.swiper--image-tiles{
    .swiper-slide__image{
        border-radius: .5rem;
    }
    .swiper-slide__description{
        background-color: $color-turquoise-transparent!important;
        border-radius: 0 0 .5rem .5rem;
        display: flex;
        flex-wrap: wrap;
    }
}

// Swiper Three   

.swiper--image-with-boxes{

    .swiper-container--alt-two{
        // height: 100%;
        
    

        .swiper-slide{
            height: auto!important;

            &:hover{
                .swiper-slide__image{
                    opacity: 1!important;
                }
            }
            
            .swiper-slide__image{
                border-radius: .5rem .5rem .5rem .5rem;
                height: 50vh;

                &.development_jobs{
                    background-image: url('../files/images/jobs/development_jobs.jpeg');
                }
                &.engineering_jobs{
                    background-image: url('../files/images/jobs/engineering_jobs.jpeg');
                }
                &.other_jobs{
                    background-image: url('../files/images/jobs/other_jobs.jpeg');
                }

                .swiper-slide__image__description{
                    background-color: $color-turquoise-transparent!important;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    border-radius: 0 0 .5rem .5rem;
                    text-align: center;
                }

                
            }


            .swiper-slide__boxes{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                list-style: none;
                padding: 0;
                margin: 4rem 0 0 0;
                order: 2;
                color: $color-black;
            
                @media screen and (min-width:$breakpoint){
                    order: unset;
                }
        
                .swiper-slide__boxes__single-box{
                    height: 15vh;
                    width: 100%;
                    box-sizing: border-box;
                    border: 2px solid $color-grey-transparent;
                    margin: 0 0 2rem 0;
                    border-radius: .5rem;

                    @media screen and (min-width:$breakpoint){
                        border: 1px solid $color-grey-transparent;
                    }
        
                    
        
                    &.last-box-row-mobile{
                        margin: 0 0 0 0;
                    }
        
                    @media screen and (min-width: $breakpoint) {
                        width: 32%;
                        &.last-box-row-desktop{
                            margin: 0 0 0 0;
                        }
                    }
        
                    .swiper-slide__boxes__single-box__top{
                        height: 50%;
                        padding: .1rem;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
        
                        .swiper-slide__boxes__single-box__top__icon{
                            
                            height: 100%;
                            margin-left: 1rem;
                            margin-right: 1rem;
                            svg{
                                width: 2rem;
                                height: 100%;
                            }
                        }
        
                    }

                    .swiper-slide__boxes__single-box__top--alt{
                        
                        display: flex;
                        justify-content: center;
                    }
        
                    .swiper-slide__boxes__single-box__bottom{
                        border-top: 0.1px solid $color-grey-transparent;
                        height: 50%;
                        padding: .5rem;
                        box-sizing: border-box;
                    }
        
                }
            }
        }
    }
}

