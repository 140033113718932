@font-face {
  font-family: "Colaborate Regular";
  src: url("../files/fonts/colaborate/colaborate_regular_macroman/ColabReg-webfont.woff");
}
@font-face {
  font-family: "OpenSans Bold";
  src: url("../files/fonts/OpenSans/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "OpenSans Regular";
  src: url("../files/fonts/OpenSans/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "Poppins Regular";
  src: url("../files/fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins Bold";
  src: url("../files/fonts/Poppins/Poppins-Bold.ttf");
}
/*
  REM @mixin

  Usage:
  @include rem(property, value);
*/
html, body {
  height: 100%;
  font-family: "Poppins Regular", sans-serif;
  scroll-behavior: smooth;
}
html.fix, body.fix {
  position: fixed;
  width: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  background-color: white;
  color: #060d0f;
}
body .inside {
  width: 90%;
  max-width: 1363px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 100%;
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.module {
  margin: 10rem 0 10rem 0;
}

.module--last {
  margin: 10rem 0 0 0;
}

.intro {
  margin-top: 5rem !important;
  margin-bottom: 2rem !important;
}

body {
  font-family: "Poppins Regular", sans-serif;
  font-size: 2.5vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #060d0f;
}
body ul, body ol {
  padding-left: 1em;
}
body h1, body h2, body h3, body h4 {
  font-family: "Poppins Regular";
  text-align: center;
  width: 100%;
  font-weight: 700;
}
@media screen and (min-width: 1000px) {
  body h1, body h2, body h3, body h4 {
    margin: 0 0 1.5rem 0;
  }
}
body h2 {
  font-size: 4vh;
  margin: 0 0 3rem 0;
}
body h3 {
  font-size: 3vh;
  margin: 4rem 0 3rem 0;
}

.footer {
  background-color: #e6eaec;
  padding: 2.5rem 0 2.5rem 0;
}
.footer .footer-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}
.footer .footer-wrapper .footer__text {
  text-align: center;
}
.footer .footer-wrapper .footer__text p {
  margin: 0;
}
.footer .footer-wrapper .footer__contact-button {
  margin: 2rem 0 2rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.footer .footer-wrapper .footer__contact-button a {
  background-color: #46a9b8;
  padding: 1rem;
  border-radius: 0.5rem;
  text-decoration: none;
  color: white;
  display: inline-block;
  transition: 450ms ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0);
}
.footer .footer-wrapper .footer__contact-button a:hover {
  background-color: white;
  color: #46a9b8;
  border: 1px solid #46a9b8;
}
.footer .footer-wrapper .footer__logo {
  width: 10rem;
}
.footer .footer-wrapper .footer__logo svg {
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}
.footer .footer-wrapper .footer__essentials {
  display: inline-block;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}
.footer .footer-wrapper .footer__essentials a {
  width: 100%;
  text-decoration: none;
  color: #060d0f;
}
.footer .footer-wrapper .footer__essentials a:hover {
  text-decoration: underline;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0px 0px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #060d0f;
}

.hamburger-box {
  width: 50px;
  height: 36px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 50px;
  height: 2px;
  background-color: #060d0f;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -17px;
}
.hamburger-inner::after {
  bottom: -17px;
}

/*
 * 3DX
 */
.hamburger--3dx .hamburger-box {
  perspective: 100px;
}
.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg);
}
.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 17px, 0) rotate(45deg);
}
.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -17px, 0) rotate(-45deg);
}

/*
 * 3DX Reverse
 */
.hamburger--3dx-r .hamburger-box {
  perspective: 100px;
}
.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg);
}
.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 17px, 0) rotate(45deg);
}
.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -17px, 0) rotate(-45deg);
}

/*
 * 3DY
 */
.hamburger--3dy .hamburger-box {
  perspective: 100px;
}
.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg);
}
.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 17px, 0) rotate(45deg);
}
.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -17px, 0) rotate(-45deg);
}

/*
 * 3DY Reverse
 */
.hamburger--3dy-r .hamburger-box {
  perspective: 100px;
}
.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg);
}
.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 17px, 0) rotate(45deg);
}
.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -17px, 0) rotate(-45deg);
}

/*
 * 3DXY
 */
.hamburger--3dxy .hamburger-box {
  perspective: 100px;
}
.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg);
}
.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 17px, 0) rotate(45deg);
}
.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -17px, 0) rotate(-45deg);
}

/*
 * 3DXY Reverse
 */
.hamburger--3dxy-r .hamburger-box {
  perspective: 100px;
}
.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}
.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 17px, 0) rotate(45deg);
}
.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -17px, 0) rotate(-45deg);
}

/*
 * Arrow
 */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-10px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-10px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
 * Arrow Right
 */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(10px, 0, 0) rotate(45deg) scale(0.7, 1);
}
.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(10px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
 * Arrow Alt
 */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-10px, -12.5px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-10px, 12.5px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
 * Arrow Alt Right
 */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(10px, -12.5px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(10px, 12.5px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
 * Arrow Turn
 */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}
.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
 * Arrow Turn Right
 */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}
.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
 * Boring
 */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none;
}
.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}
.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*
 * Collapse
 */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
  top: -34px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -17px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Collapse Reverse
 */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r .hamburger-inner::after {
  top: -34px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -17px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Elastic
 */
.hamburger--elastic .hamburger-inner {
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic .hamburger-inner::before {
  top: 17px;
  transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic .hamburger-inner::after {
  top: 34px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 17px, 0) rotate(135deg);
  transition-delay: 0.075s;
}
.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -34px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

/*
 * Elastic Reverse
 */
.hamburger--elastic-r .hamburger-inner {
  top: 1px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic-r .hamburger-inner::before {
  top: 17px;
  transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic-r .hamburger-inner::after {
  top: 34px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 17px, 0) rotate(-135deg);
  transition-delay: 0.075s;
}
.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -34px, 0) rotate(270deg);
  transition-delay: 0.075s;
}

/*
 * Emphatic
 */
.hamburger--emphatic {
  overflow: hidden;
}
.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::after {
  top: 17px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -100px;
  top: -100px;
  transform: translate3d(100px, 100px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -100px;
  top: -100px;
  transform: translate3d(-100px, 100px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
 * Emphatic Reverse
 */
.hamburger--emphatic-r {
  overflow: hidden;
}
.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r .hamburger-inner::after {
  top: 17px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -100px;
  top: 100px;
  transform: translate3d(100px, -100px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -100px;
  top: 100px;
  transform: translate3d(-100px, -100px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
 * Minus
 */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}
.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}
.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}
.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}

/*
 * Slider
 */
.hamburger--slider .hamburger-inner {
  top: 1px;
}
.hamburger--slider .hamburger-inner::before {
  top: 17px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider .hamburger-inner::after {
  top: 34px;
}
.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 17px, 0) rotate(45deg);
}
.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-7.1428571429px, -15px, 0);
  opacity: 0;
}
.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -34px, 0) rotate(-90deg);
}

/*
 * Slider Reverse
 */
.hamburger--slider-r .hamburger-inner {
  top: 1px;
}
.hamburger--slider-r .hamburger-inner::before {
  top: 17px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider-r .hamburger-inner::after {
  top: 34px;
}
.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 17px, 0) rotate(-45deg);
}
.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(7.1428571429px, -15px, 0);
  opacity: 0;
}
.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -34px, 0) rotate(90deg);
}

/*
 * Spin
 */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Spin Reverse
 */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Spring
 */
.hamburger--spring .hamburger-inner {
  top: 1px;
  transition: background-color 0s 0.13s linear;
}
.hamburger--spring .hamburger-inner::before {
  top: 17px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring .hamburger-inner::after {
  top: 34px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}
.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 17px, 0) rotate(45deg);
}
.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 17px, 0) rotate(-45deg);
}

/*
 * Spring Reverse
 */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring-r .hamburger-inner::after {
  top: -34px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}
.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -17px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}
.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Stand
 */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}
.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}
.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Stand Reverse
 */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}
.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}
.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Squeeze
 */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Vortex
 */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}
.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}
.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}
.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}
.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}

/*
 * Vortex Reverse
 */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}
.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}
.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}
.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}
.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.header {
  z-index: 6;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 3vw 0 3vw 0;
  background-color: white;
  -webkit-box-shadow: 0px 6px 8px -1px #90989d;
  -moz-box-shadow: 0px 6px 8px -1px #90989d;
  box-shadow: 0px 6px 8px -1px #90989d;
}
.header .header__content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .header__content .header__content__logo a {
  display: flex;
  align-items: center;
  width: 100%;
}
.header .header__content .header__content__logo a svg {
  width: 100%;
}
.header .header__content .header__content__nav {
  display: none;
}
@media screen and (min-width: 1000px) {
  .header .header__content .header__content__nav {
    display: unset;
  }
}
.header .header__content .header__content__nav ul {
  display: flex;
  list-style: none;
  margin: 0;
}
.header .header__content .header__content__nav ul li {
  font-size: 4vh;
  font-family: "Poppins Regular";
  padding: 0 2rem 0 2rem;
}
.header .header__content .header__content__nav ul li a {
  text-decoration: none;
  color: #060d0f;
  position: relative;
  display: inline-block;
}
.header .header__content .header__content__nav ul li a::after {
  display: block;
  content: "";
  position: absolute;
  border-bottom: 2px solid #060d0f;
  transform: scaleX(0);
  transition: transform 450ms ease-in-out;
  bottom: -0.2rem;
  left: 0;
  right: 0;
  width: 100%;
}
.header .header__content .header__content__nav ul li a:hover::after {
  transform: scaleX(1);
}
.header .header__content .hamburger-wrapper {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
@media screen and (min-width: 1000px) {
  .header .header__content .hamburger-wrapper {
    display: none;
  }
}
.header .header__content .hamburger-wrapper #hamburger:focus {
  outline: none;
}

.scrolled .header {
  position: fixed;
  top: 0;
  animation-name: HeaderSlideIn;
  animation-duration: 0.5s;
  padding: 1vw 0 1vw 0;
}

#mobile-nav-wrapper {
  z-index: 5;
  width: 100%;
  position: fixed;
  transform: translateX(100%);
  transition: 450ms ease-in-out;
  background-color: white;
  overflow-y: auto;
  font-family: "Poppins Regular";
}
@media screen and (min-width: 1000px) {
  #mobile-nav-wrapper {
    display: none;
  }
}
#mobile-nav-wrapper.active {
  transform: translateX(0);
}
#mobile-nav-wrapper .mobile-nav {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
#mobile-nav-wrapper .mobile-nav .mobile-nav__section {
  list-style: none;
  width: 100%;
  padding-left: 0;
}
#mobile-nav-wrapper .mobile-nav .mobile-nav__section li a {
  text-decoration: none;
}
#mobile-nav-wrapper .mobile-nav .mobile-nav__section-navpoints {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 5rem 0 5rem 0;
  font-family: "Poppins Regular";
}
#mobile-nav-wrapper .mobile-nav .mobile-nav__section-navpoints li {
  width: 90%;
  text-align: center;
  font-size: 3vh;
  font-weight: 700;
  padding: 5% 0 5% 0;
  border-top: 0.1px solid #90989d;
}
#mobile-nav-wrapper .mobile-nav .mobile-nav__section-navpoints li:first-child {
  border-top: none;
}
#mobile-nav-wrapper .mobile-nav .mobile-nav__section-navpoints li a {
  text-decoration: none;
  color: #46a9b8;
}
#mobile-nav-wrapper .mobile-nav .mobile-nav__section-essentials {
  margin-bottom: 10rem;
}
#mobile-nav-wrapper .mobile-nav .mobile-nav__section-essentials li {
  width: 100%;
  font-size: 1.5vh;
  text-align: center;
}
#mobile-nav-wrapper .mobile-nav .mobile-nav__section-essentials li:last-child {
  margin: 5rem 0 0 0;
}
#mobile-nav-wrapper .mobile-nav .mobile-nav__section-essentials li a {
  color: #060d0f;
}
#mobile-nav-wrapper .mobile-nav .mobile-nav__section-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px -6px 8px -1px #90989d;
  -moz-box-shadow: 0px -6px 8px -1px #90989d;
  box-shadow: 0px -6px 8px -1px #90989d;
  height: 10rem;
}
#mobile-nav-wrapper .mobile-nav .mobile-nav__section-footer .mobile-nav__section-footer__icon-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 90%;
  padding: 0;
  margin: 0;
}
#mobile-nav-wrapper .mobile-nav .mobile-nav__section-footer .mobile-nav__section-footer__icon-wrapper li {
  list-style: none;
}
#mobile-nav-wrapper .mobile-nav .mobile-nav__section-footer .mobile-nav__section-footer__icon-wrapper li a {
  display: flex;
  justify-content: space-between;
}
#mobile-nav-wrapper .mobile-nav .mobile-nav__section-footer .mobile-nav__section-footer__icon-wrapper li a svg {
  height: 8vw;
}

.fixed-contact {
  display: none;
  position: fixed;
  right: 0;
  top: 50%;
  background-color: #46a9b8;
  border-radius: 0.5rem 0 0 0.5rem;
  width: 4%;
}
@media screen and (min-width: 1000px) {
  .fixed-contact {
    display: unset;
  }
}
.fixed-contact ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.fixed-contact ul li a {
  transition: 450ms ease-in-out;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0 0.5rem 0;
  height: 2rem;
}
.fixed-contact ul li a:first-child {
  padding-top: 1rem;
}
.fixed-contact ul li a:last-child {
  padding-bottom: 1rem;
}
.fixed-contact ul li a:hover {
  opacity: 0.7;
}
.fixed-contact ul li a svg {
  width: 50%;
}

.anchor {
  position: absolute;
  top: -12rem;
  left: 0;
}

@keyframes HeaderSlideIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.swiper a {
  color: #90989d;
  transition: 450ms ease-in-out;
}
.swiper a:hover {
  color: #46a9b8;
}
.swiper h2 {
  text-align: center;
}
.swiper .swiper-container, .swiper .swiper-container--alt, .swiper .swiper-container--alt-two {
  min-height: 50vh;
  max-height: 100%;
  border-radius: 0.5rem;
  color: white;
}
.swiper .swiper-container .swiper-slide, .swiper .swiper-container--alt .swiper-slide, .swiper .swiper-container--alt-two .swiper-slide {
  height: 50vh;
}
@media screen and (min-width: 1000px) {
  .swiper .swiper-container .swiper-slide .swiper-slide__description, .swiper .swiper-container--alt .swiper-slide .swiper-slide__description, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__description {
    opacity: 0;
    transition: 450ms ease-in-out;
  }
  .swiper .swiper-container .swiper-slide:hover .swiper-slide__description, .swiper .swiper-container--alt .swiper-slide:hover .swiper-slide__description, .swiper .swiper-container--alt-two .swiper-slide:hover .swiper-slide__description {
    opacity: 1;
  }
  .swiper .swiper-container .swiper-slide:hover .swiper-slide__image, .swiper .swiper-container--alt .swiper-slide:hover .swiper-slide__image, .swiper .swiper-container--alt-two .swiper-slide:hover .swiper-slide__image {
    opacity: 0.7;
  }
}
.swiper .swiper-container .swiper-slide .swiper-slide__image, .swiper .swiper-container--alt .swiper-slide .swiper-slide__image, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__image {
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  transition: 450ms ease-in-out;
}
@media screen and (min-width: 1000px) {
  .swiper .swiper-container .swiper-slide .swiper-slide__image, .swiper .swiper-container--alt .swiper-slide .swiper-slide__image, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__image {
    background-position: unset;
  }
}
.swiper .swiper-container .swiper-slide .swiper-slide__image.sector_one, .swiper .swiper-container--alt .swiper-slide .swiper-slide__image.sector_one, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__image.sector_one {
  background-image: url("../files/images/sectors/sector_one.jpg");
}
.swiper .swiper-container .swiper-slide .swiper-slide__image.sector_two, .swiper .swiper-container--alt .swiper-slide .swiper-slide__image.sector_two, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__image.sector_two {
  background-image: url("../files/images/sectors/sector_two.jpg");
}
.swiper .swiper-container .swiper-slide .swiper-slide__image.sector_three, .swiper .swiper-container--alt .swiper-slide .swiper-slide__image.sector_three, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__image.sector_three {
  background-image: url("../files/images/sectors/sector_three.jpg");
}
.swiper .swiper-container .swiper-slide .swiper-slide__image.sector_four, .swiper .swiper-container--alt .swiper-slide .swiper-slide__image.sector_four, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__image.sector_four {
  background-image: url("../files/images/sectors/sector_four.jpg");
}
.swiper .swiper-container .swiper-slide .swiper-slide__image.sector_five, .swiper .swiper-container--alt .swiper-slide .swiper-slide__image.sector_five, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__image.sector_five {
  background-image: url("../files/images/sectors/sector_five.jpg");
}
.swiper .swiper-container .swiper-slide .swiper-slide__image.sector_six, .swiper .swiper-container--alt .swiper-slide .swiper-slide__image.sector_six, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__image.sector_six {
  background-image: url("../files/images/sectors/sector_six.jpg");
}
.swiper .swiper-container .swiper-slide .swiper-slide__image.sector_seven, .swiper .swiper-container--alt .swiper-slide .swiper-slide__image.sector_seven, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__image.sector_seven {
  background-image: url("../files/images/sectors/sector_seven.jpg");
}
.swiper .swiper-container .swiper-slide .swiper-slide__image.christian_placeholder, .swiper .swiper-container--alt .swiper-slide .swiper-slide__image.christian_placeholder, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__image.christian_placeholder {
  background-image: url("../files/images/team/avatar_man.png");
}
.swiper .swiper-container .swiper-slide .swiper-slide__image.lothar_placeholder, .swiper .swiper-container--alt .swiper-slide .swiper-slide__image.lothar_placeholder, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__image.lothar_placeholder {
  background-image: url("../files/images/team/lothar_gesslein.jpeg");
}
.swiper .swiper-container .swiper-slide .swiper-slide__image.jonathan_placeholder, .swiper .swiper-container--alt .swiper-slide .swiper-slide__image.jonathan_placeholder, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__image.jonathan_placeholder {
  background-image: url("../files/images/team/jonathan_schweppe.jpg");
}
.swiper .swiper-container .swiper-slide .swiper-slide__image.fynn_placeholder, .swiper .swiper-container--alt .swiper-slide .swiper-slide__image.fynn_placeholder, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__image.fynn_placeholder {
  background-image: url("../files/images/team/avatar_man.png");
}
.swiper .swiper-container .swiper-slide .swiper-slide__image.malte_placeholder, .swiper .swiper-container--alt .swiper-slide .swiper-slide__image.malte_placeholder, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__image.malte_placeholder {
  background-image: url("../files/images/team/malte_muench.png");
}
.swiper .swiper-container .swiper-slide .swiper-slide__image.daniel_placeholder, .swiper .swiper-container--alt .swiper-slide .swiper-slide__image.daniel_placeholder, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__image.daniel_placeholder {
  background-image: url("../files/images/team/avatar_man.png");
}
.swiper .swiper-container .swiper-slide .swiper-slide__image.tobias_placeholder, .swiper .swiper-container--alt .swiper-slide .swiper-slide__image.tobias_placeholder, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__image.tobias_placeholder {
  background-image: url("../files/images/team/avatar_man.png");
}
.swiper .swiper-container .swiper-slide .swiper-slide__description, .swiper .swiper-container--alt .swiper-slide .swiper-slide__description, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__description {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(70, 169, 184, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.swiper .swiper-container .swiper-slide .swiper-slide__description p, .swiper .swiper-container--alt .swiper-slide .swiper-slide__description p, .swiper .swiper-container--alt-two .swiper-slide .swiper-slide__description p {
  width: 90%;
}

.swiper-button-prev, .swiper-button-next {
  color: #46a9b8 !important;
  top: 40% !important;
}
.swiper-button-prev::after, .swiper-button-next::after {
  font-size: 8vh !important;
}
@media screen and (min-width: 1000px) {
  .swiper-button-prev, .swiper-button-next {
    top: 50% !important;
  }
}

.swiper-button-prev {
  left: 5vw !important;
}

.swiper-button-next {
  right: 5vw !important;
}

.swiper-button-prev--alt, .swiper-button-next--alt {
  top: 21vh !important;
}

.swiper--image-tiles .swiper-slide__image {
  border-radius: 0.5rem;
}
.swiper--image-tiles .swiper-slide__description {
  background-color: rgba(70, 169, 184, 0.7) !important;
  border-radius: 0 0 0.5rem 0.5rem;
  display: flex;
  flex-wrap: wrap;
}

.swiper--image-with-boxes .swiper-container--alt-two .swiper-slide {
  height: auto !important;
}
.swiper--image-with-boxes .swiper-container--alt-two .swiper-slide:hover .swiper-slide__image {
  opacity: 1 !important;
}
.swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__image {
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  height: 50vh;
}
.swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__image.development_jobs {
  background-image: url("../files/images/jobs/development_jobs.jpeg");
}
.swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__image.engineering_jobs {
  background-image: url("../files/images/jobs/engineering_jobs.jpeg");
}
.swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__image.other_jobs {
  background-image: url("../files/images/jobs/other_jobs.jpeg");
}
.swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__image .swiper-slide__image__description {
  background-color: rgba(70, 169, 184, 0.7) !important;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0 0 0.5rem 0.5rem;
  text-align: center;
}
.swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__boxes {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 4rem 0 0 0;
  order: 2;
  color: #060d0f;
}
@media screen and (min-width: 1000px) {
  .swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__boxes {
    order: unset;
  }
}
.swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__boxes .swiper-slide__boxes__single-box {
  height: 15vh;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid rgba(144, 152, 157, 0.7);
  margin: 0 0 2rem 0;
  border-radius: 0.5rem;
}
@media screen and (min-width: 1000px) {
  .swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__boxes .swiper-slide__boxes__single-box {
    border: 1px solid rgba(144, 152, 157, 0.7);
  }
}
.swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__boxes .swiper-slide__boxes__single-box.last-box-row-mobile {
  margin: 0 0 0 0;
}
@media screen and (min-width: 1000px) {
  .swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__boxes .swiper-slide__boxes__single-box {
    width: 32%;
  }
  .swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__boxes .swiper-slide__boxes__single-box.last-box-row-desktop {
    margin: 0 0 0 0;
  }
}
.swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__boxes .swiper-slide__boxes__single-box .swiper-slide__boxes__single-box__top {
  height: 50%;
  padding: 0.1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__boxes .swiper-slide__boxes__single-box .swiper-slide__boxes__single-box__top .swiper-slide__boxes__single-box__top__icon {
  height: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
}
.swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__boxes .swiper-slide__boxes__single-box .swiper-slide__boxes__single-box__top .swiper-slide__boxes__single-box__top__icon svg {
  width: 2rem;
  height: 100%;
}
.swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__boxes .swiper-slide__boxes__single-box .swiper-slide__boxes__single-box__top--alt {
  display: flex;
  justify-content: center;
}
.swiper--image-with-boxes .swiper-container--alt-two .swiper-slide .swiper-slide__boxes .swiper-slide__boxes__single-box .swiper-slide__boxes__single-box__bottom {
  border-top: 0.1px solid rgba(144, 152, 157, 0.7);
  height: 50%;
  padding: 0.5rem;
  box-sizing: border-box;
}

.image-text {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.image-text p {
  text-align: left;
}
.image-text p a {
  color: #90989d;
  transition: 450ms ease-in-out;
}
.image-text p a:hover {
  color: #46a9b8;
}
.image-text .image-text__image-wrapper, .image-text .image-text__text {
  width: 100%;
}
@media screen and (min-width: 1000px) {
  .image-text .image-text__image-wrapper, .image-text .image-text__text {
    width: 49%;
  }
}
.image-text .image-text__image-wrapper {
  position: relative;
  padding-top: 75%;
  margin-bottom: 4rem;
}
@media screen and (min-width: 1000px) {
  .image-text .image-text__image-wrapper {
    padding-top: 37.5%;
    margin-bottom: 0;
  }
}
.image-text .image-text__image-wrapper p {
  font-size: 0.5rem;
  margin: 0;
}
.image-text .image-text__image-wrapper p a {
  color: #90989d;
  transition: 450ms ease-in-out;
}
.image-text .image-text__image-wrapper p a:hover {
  color: #46a9b8;
}
.image-text .image-text__image-wrapper .image-text__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.image-text .image-text__image-wrapper--alt {
  padding-top: 48%;
}
@media screen and (min-width: 1000px) {
  .image-text .image-text__image-wrapper--alt {
    padding-top: 24%;
    margin-bottom: 0;
  }
}
.image-text .image-text__image-wrapper--alt-two {
  padding-top: 69%;
}
@media screen and (min-width: 1000px) {
  .image-text .image-text__image-wrapper--alt-two {
    padding-top: 34%;
    margin-bottom: 0;
  }
}
.image-text .image-text__text p {
  margin-top: 0;
}
.image-text .image-text__text p a {
  color: #90989d;
  text-align: center;
  transition: 450ms ease-in-out;
}
.image-text .image-text__text p a:hover {
  color: #46a9b8;
}
.image-text .image-text__text--alt {
  text-align: center;
}
@media screen and (min-width: 1000px) {
  .image-text .image-text__text--alt {
    width: 100%;
  }
}
.image-text .image-text__text--alt p {
  margin: 0;
}
.image-text .image-text__boxes {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 2rem 0 0 0;
}
.image-text .image-text__boxes .image-text__boxes__single-box {
  height: 15vh;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid rgba(144, 152, 157, 0.7);
  margin: 0 0 2rem 0;
  border-radius: 0.5rem;
}
@media screen and (min-width: 1000px) {
  .image-text .image-text__boxes .image-text__boxes__single-box {
    border: 1px solid rgba(144, 152, 157, 0.7);
  }
}
.image-text .image-text__boxes .image-text__boxes__single-box.last-box-row-mobile {
  margin: 0 0 0 0;
}
@media screen and (min-width: 1000px) {
  .image-text .image-text__boxes .image-text__boxes__single-box {
    width: 32%;
  }
  .image-text .image-text__boxes .image-text__boxes__single-box.last-box-row-desktop {
    margin: 0 0 0 0;
  }
}
.image-text .image-text__boxes .image-text__boxes__single-box .image-text__boxes__single-box__top {
  height: 50%;
  padding: 1vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.image-text .image-text__boxes .image-text__boxes__single-box .image-text__boxes__single-box__top .image-text__boxes__single-box__top__icon {
  width: 10%;
  margin-left: 1rem;
  margin-right: 1rem;
}
.image-text .image-text__boxes .image-text__boxes__single-box .image-text__boxes__single-box__top .image-text__boxes__single-box__top__icon svg {
  width: auto;
  max-height: 5vh;
}
.image-text .image-text__boxes .image-text__boxes__single-box .image-text__boxes__single-box__top--alt p {
  width: 100%;
  text-align: center;
}
.image-text .image-text__boxes .image-text__boxes__single-box .image-text__boxes__single-box__bottom {
  border-top: 0.1px solid rgba(144, 152, 157, 0.7);
  height: 100%;
  padding: 1vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.image-text .image-text__boxes .image-text__boxes__single-box .image-text__boxes__single-box__bottom p {
  text-align: center;
}
.image-text .image-text__boxes .image-text__boxes__single-box .image-text__boxes__single-box__bottom .image-text__boxes__single-box__bottom__image-wrapper {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-text .image-text__boxes .image-text__boxes__single-box .image-text__boxes__single-box__bottom .image-text__boxes__single-box__bottom__image-wrapper img {
  max-width: 60%;
}
.image-text .image-text__boxes--alt .image-text__boxes__single-box {
  height: 25vh !important;
}

.text {
  margin-top: 0 !important;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.text .text__with-image {
  margin-bottom: 4rem;
  align-self: flex-start;
}
.text .text__left, .text .text__right {
  width: 100%;
}
.text .text__left :first-child, .text .text__right :first-child {
  margin-top: 0;
}
@media screen and (min-width: 1000px) {
  .text .text__left, .text .text__right {
    width: 47%;
  }
  .text .text__left :last-child, .text .text__right :last-child {
    margin-bottom: 0;
  }
}
.text .text__left a, .text .text__right a {
  color: #90989d;
  transition: 450ms ease-in-out;
}
.text .text__left a:hover, .text .text__right a:hover {
  color: #46a9b8;
}
.text .text__right :last-child {
  margin-bottom: 0;
}

.legals {
  margin: 5rem 0 5rem 0;
}
.legals a {
  color: #90989d;
  transition: 450ms ease-in-out;
}
.legals a:hover {
  color: #46a9b8;
}
.legals h2, .legals h3 {
  text-align: left;
}