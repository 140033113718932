.footer{
    background-color: $color-light-grey;
    padding: 2.5rem 0 2.5rem 0;
    

    .footer-wrapper{

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;

        .footer__text{
            text-align: center;

            p{
                margin: 0;
            }
        }

        .footer__contact-button{
            
            margin: 2rem 0 2rem 0;
            width: 100%;
            display: flex;
            justify-content: center;
            

            a {
                background-color: $color-turquoise;
                padding: 1rem;
                border-radius: .5rem;
                text-decoration: none;
                color: $color-white;
                display: inline-block;
                transition: 450ms ease-in-out;
                border: 1px solid rgba(255,255,255,0);

                &:hover{
                    background-color: $color-white;
                    color: $color-turquoise;
                    border: 1px solid $color-turquoise;
                }
            }
        }
        .footer__logo{
            width: 10rem;

            svg{
                width: 100%;
                height: 100%;
                filter: grayscale(100%);
            }
        }

        .footer__essentials{
            display: inline-block;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            text-align: center;

            a{
                width: 100%;
                text-decoration: none;
                color: $color-black;

                &:hover{
                    text-decoration: underline;
                }
            }
        }

    }

        
}